// Vendors
import 'locomotive-scroll/dist/locomotive-scroll.css';
import LocomotiveScroll from 'locomotive-scroll';
import { gsap } from "gsap";

//media assets
import play_button from './images/play.png'
import pause from './images/pause.png'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { TweenLite } from 'gsap/gsap-core';
import { TweenMax } from 'gsap/gsap-core';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAKJyamZmM7gn0ObEsNqP5mBSqm7wsyqa4",
    authDomain: "poka-studios.firebaseapp.com",
    projectId: "poka-studios",
    storageBucket: "poka-studios.appspot.com",
    messagingSenderId: "270109414544",
    appId: "1:270109414544:web:c4cffb50f6901247bef415",
    measurementId: "G-NBKKQNZCJX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const locomotiveScroll = new LocomotiveScroll({

});



//Media
import sound_on from './images/sound_on.png'
import sound_off from './images/sound_toggle.png'

/* Custom event */
window.addEventListener("scrollEvent", (e) => {
    const { target, way } = e.detail;

    if (way === "enter") {
        target.style.opacity = 1;
        target.style.paddingTop = 0
    } else {
        target.style.opacity = 0;
    }
});

const works_heading = document.querySelector('#works_heading')
const challenge_img = document.querySelector('.challenge_img')
const challenge_content = document.querySelector('#challenge_content')
const contact_img = document.querySelector('.contact_img')
const contact_content = document.querySelector('#contact_content')
const footer_arr = [challenge_img, challenge_content, contact_img, contact_content]
const arrow_up = document.querySelector('#arrow_up')
const arrow_down = document.querySelector('#arrow_down')
const video_trey = document.querySelector('.video_trey')

const works_array = 'OUR WORKS'.split('')


works_array.forEach(items => {
    const span = document.createElement('span')
    span.textContent = items

    works_heading.appendChild(span)



})

const spans = works_heading.querySelectorAll('span')

window.addEventListener('animTrigger', () => {
    gsap.to(spans, {
        alpha: 1,
        top: 0,
        stagger: .1,
        duration: 1.5,
        ease: 'power4.out'
    })
})

window.addEventListener('contactTrigger', () => {
    gsap.to(footer_arr, {
        alpha: 1,
        stagger: .2,
        duration: 1.5,
        ease: 'power4.out'
    })
})

window.addEventListener('latestTrigger', () => {
    //latest Project Loader
    const svg = document.querySelector('svg circle')
    const content_trey = document.querySelector('.content_trey')
    const flex2_trey = document.querySelector('.flex2_trey')
    const last_night_caption_trey = document.querySelector('.last_night_caption_trey')
    const poster2_trey = document.querySelector('.poster2_trey')
    const latest_array = [content_trey, flex2_trey, last_night_caption_trey, poster2_trey]

    const timer = 12000
    var index = 1;
    var total_index = 2;
    const duration_time = 11
    let loadInterval

    const svg_tl = gsap.timeline({ paused: false })



    svg_tl.to(svg, {
        strokeDashoffset: 0,
        duration: duration_time
    })


    function timeInterval() {
        loadInterval = setInterval(() => {
            if (index < total_index) {
                svg_tl.to(svg, {
                    strokeDashoffset: 251,
                    duration: duration_time
                })
                svg.style.stroke = 'red'
                gsap.to(latest_array, {
                    transform: 'translate(-50%)',
                    stagger: .2,
                    ease: 'power4.out',
                    duration: 1
                })
                index++
            } else {
                index = 1
                svg_tl.to(svg, {
                    strokeDashoffset: 0,
                    duration: duration_time
                })
                gsap.to(latest_array, {
                    transform: 'translate(0%)',
                    stagger: .2,
                    ease: 'power4.out',
                    duration: 1
                })
                svg.style.stroke = 'blue'
                // loading_bar.style.transition = 'none'
                // loading_bar.style.width = '0'
                //loading_bar.style.display='none'
            }

        }, timer)
    }


    timeInterval()

    const pause_button = document.querySelector('.pause_button')
    var pause_bool = true;

    pause_bool = !pause_bool
    pause_button.onclick = (e) => {
        svg_tl.paused(!svg_tl.paused());
        if (pause_bool = !pause_bool) {
            e.target.setAttribute('src', play_button)
            clearInterval(loadInterval)

        } else {
            e.target.setAttribute('src', pause)
            timeInterval()
        }
    }
})



var move_amount = 80
var value = 0
var index = 1
var total_index = video_trey.children.length


function reelDown(){
    if (index < total_index) {

        video_trey.style.transform = `translateY(${value -= move_amount}vh)`
        index++
    }
}

arrow_down.onclick = () => {
    
    reelDown()

}

function reelUp(){
    if (index > 1) {
        index--
        video_trey.style.transform = `translateY(${value += move_amount}vh)`
        
    }
}

arrow_up.onclick = () => {

    reelUp()


}

//Scroll Trigger
window.addEventListener('reelTrigger', () => {
    const reel_heading = document.querySelector('.reel_heading')
    const reel_detail = document.querySelector('#details')
    const reels = document.querySelector('#reels')
    const reel_buttons = document.querySelector('#reel_buttons')
    const reel_array = [reel_heading, reel_detail, reels, reel_buttons]

    gsap.to(reel_array, {
        alpha: 1,
        stagger: .2
    })

})


// Toggle light or dark mode
const toggle_mode = document.querySelector('#toggle_mode')
var toggle = true
var key = 'toggle_key'
toggle = !toggle
const local_toggle = localStorage.getItem(key)



toggle_mode.onclick = () => {


    if (toggle = !toggle) {
        localStorage.setItem(key, toggle)
        document.body.classList.add('darkmode')


    } else {
        localStorage.setItem(key, toggle)
        document.body.classList.remove('darkmode')

    }
}

if (local_toggle === 'true') {
    document.body.classList.add('darkmode')

}

if (local_toggle === 'false') {
    document.body.classList.remove('darkmode')

}

//Responsive Navbar
const nav = document.querySelector('nav')
var lastScrollPosition = 0

window.addEventListener('scroll', () => {
    // Get the current scroll position
    var currentScrollPosition = window.scrollY;
    //console.log(currentScrollPosition)

    if (currentScrollPosition - lastScrollPosition > 0) {
        nav.classList.add('add_nav')
    } else {
        nav.classList.remove('add_nav')
        nav.style.height = 'auto'
    }

    lastScrollPosition = currentScrollPosition
})

const reel_button = document.querySelector('#reel_button')
const banner_trey = document.querySelector('.banner_trey')
const banner_mute = document.querySelector('.banner_mute')
const banner_caption = document.querySelector('.banner_caption')
const banner_description = document.querySelector('.banner_description')
var reel_bool = true

reel_bool = !reel_bool;




//Loading Animation starts here..
const loading = document.querySelector('.loading')
const loading_screen = document.querySelector('.loading_screen')
const timelne = gsap.timeline()

if (loading) {
    gsap.to(loading, {
        delay: 1,
        transform: 'scale(1.2)',
        duration: 3,

        onComplete: () => {
            timelne.to(loading, {
                transform: 'scale(1.3)',
                duration: .5,
            })

            timelne.to(loading, {
                delay: .3,
                transform: 'scale(1)',
                duration: .25,
                onComplete: () => {
                    loading.style.maskImage = 'none'
                    loading_screen.remove()
                }

            })
        }
    })
}

//Menu pane codes here...
const menu = document.querySelector('.menu')
var toggle_bool = true

toggle_bool = !toggle_bool
menu.onclick = () => {
    //nav.classList.toggle('toggle_nav')

    if (toggle_bool = !toggle_bool) {
        gsap.fromTo(nav, {
            height: 'auto',
        }, {
            height: '100dvh',
            duration: .3,
            ease: 'power4.in'
        })
    } else {
        gsap.to(nav, {
            height: 'auto',
            duration: .03,
            ease: 'power4.out'
        })
    }
}

//Mobile view codes
const main = document.querySelector('.main')
const portfolio_container = document.querySelector('.portfolio_container')
const card1 = document.querySelector('#card1')
const card2 = document.querySelector('#card2')
const card3 = document.querySelector('#card3')
const card4 = document.querySelector('#card4')
const caption = document.querySelector('#caption')
const mobile_screen = window.matchMedia('(max-width:500px)')

if (mobile_screen.matches) {
    document.addEventListener('scroll', (e) => {

        var scrollTop = document.scrollingElement.scrollTop;
        var main_position = main.offsetTop;
        const portfolio_position = portfolio_container.offsetTop;


        // if(scrollTop > main_position+300){
        //     caption.style.transform = `translateY(-${(scrollTop-(main_position+600))/10}%)`
        // }

        if (scrollTop > portfolio_position - 300) {
            card1.style.transform = `translate(-3%,-${(scrollTop - (portfolio_position - 300)) / 13}%)`
        }

        if (scrollTop > portfolio_position - 300) {
            card2.style.transform = `translate(0%,-${(scrollTop - (portfolio_position - 300)) / 6}%)`
        }
        if (scrollTop > portfolio_position - 300) {
            card3.style.transform = `translate(0%,-${(scrollTop - (portfolio_position - 300)) / 9}%)`
        }

        if (scrollTop > portfolio_position + 200) {
            card4.style.transform = `translate(0%,-${(scrollTop - (portfolio_position + 200)) / 13}%)`
        }

    })
}



//Reels Section js codes here...
const reel_descrip = document.querySelector('.reel_descrip')
const summary = String(reel_descrip.innerText).slice(0, 60)

console.log(summary)

reel_descrip.innerHTML = summary + '...'

//Hover for video preview
const trigger = document.querySelector('.viz_trigger')
const music_trigger = document.querySelector('.music_trigger')
const film_trigger = document.querySelector('.film_trigger')
const film_cover_vid = document.querySelector('.film_cover video')
const cover_vid = document.querySelector('.cover video')
const music_cover_vid = document.querySelector('.music_cover video')
const music_cover = document.querySelector('.music_cover')
const film_cover = document.querySelector('.film_cover')
const film_details = document.querySelector('.film_details')
const film_p = document.querySelector('.film_p')
const card_2 = document.querySelector('.card2')
const music_details = document.querySelector('.music_details')
const viz_cover = document.querySelector('.viz_cover')
const viz_details = document.querySelector('.viz_details')
const illustration_cover = document.querySelector('.illustration_cover')
const illustration_details = document.querySelector('.illustration_details')

trigger.onmouseover = (e) => {


    cover_vid.style.opacity = 1,
        cover_vid.style.visibility = 'visible'
    cover_vid.style.display = 'block'
    viz_cover.style.transform = 'scale(1.2)'
    viz_details.style.transform = 'translateY(max(60px,5vh))'


    console.log(e.target.parentNode)

}

trigger.onmouseleave = (e) => {

    cover_vid.style.opacity = 0,
        cover_vid.style.visibility = 'hidden'
    cover_vid.style.display = 'none'
    viz_cover.style.transform = 'scale(1)'
    viz_details.style.transform = 'translateY(0px)'
}

music_trigger.onmouseover = (e) => {
    music_cover_vid.style.opacity = 1
    music_cover_vid.style.visibility = 'visible'
    music_cover_vid.style.display = 'block'
    music_cover.style.transform = 'scale(1.2)'

    console.log(e.target.parentNode)
    music_details.style.transform = 'translateY(max(60px,5vh))'
}

music_trigger.onmouseleave = (e) => {
    music_cover_vid.style.opacity = 0
    music_cover_vid.style.visibility = 'hidden'
    music_cover_vid.style.display = 'none'
    music_cover.style.transform = 'scale(1)'
    music_details.style.transform = 'translateY(0px)'
}

film_trigger.onmouseover = () => {
    film_cover_vid.style.opacity = 1
    film_cover_vid.style.visibility = 'visible'
    film_cover_vid.style.display = 'block'
    film_cover.style.transform = 'scale(1.05)'
    film_details.style.transform = 'translateY(50px)'
    film_p.style.transform = 'translateY(50px)'
}

film_trigger.onmouseleave = () => {
    film_cover_vid.style.opacity = 0
    film_cover_vid.style.visibility = 'hidden'
    film_cover_vid.style.display = 'none'
    film_cover.style.transform = 'scale(1)'
    film_details.style.transform = 'translateY(0px)'
    film_p.style.transform = 'translateY(0px)'
}

card4.onmouseover = () => {
    illustration_cover.style.transform = 'scale(1.1)'
    illustration_details.style.transform = 'translateY(max(40px,5vh))'
}

card4.onmouseleave = () => {
    illustration_cover.style.transform = 'scale(1)'
    illustration_details.style.transform = 'translateY(0px)'
}



//Toggle Sound
const banner_mute_img = document.querySelector('.banner_mute img')
var sound_bool = true
const last_night_trailer = document.querySelector('#last_night_trailer')
const reel_video = document.querySelector('#reel_video')

sound_bool = !sound_bool

banner_mute.onclick = () => {
    if (sound_bool = !sound_bool) {
        banner_mute_img.setAttribute('src', sound_on)
    } else {
        banner_mute_img.setAttribute('src', sound_off)
    }
    

    last_night_trailer.muted =!last_night_trailer.muted


}

reel_button.onclick = () => {

    last_night_trailer.muted=true
    reel_video.muted = true
    if(sound_bool==true){
        banner_mute_img.setAttribute('src', sound_off)
        sound_bool=false;
    }

    if (reel_bool = !reel_bool) {
        banner_trey.style.left = '-100%'
        reel_button.innerHTML = '&larr; Last Night'
        banner_caption.textContent = 'Our Showreel'
        banner_description.textContent = ' “A project that kept our creative adrenaline juices flowing, it could have been a cocktail recipe."'


    } else {
        banner_trey.style.left = '0%'
        reel_button.innerHTML = 'Showreel &rarr;'
        banner_caption.textContent = 'Last Night'
        banner_description.textContent = ' “One. Night. Of. Pleasure? Started out that way The Nightmare was in the Shadows…Waiting”'

    }

    if (reel_bool == false) {
        banner_mute.onclick = () => {
           
            if (sound_bool = !sound_bool) {
                banner_mute_img.setAttribute('src', sound_on)
               
            } else {
                banner_mute_img.setAttribute('src', sound_off)
            }
            last_night_trailer.muted =!last_night_trailer.muted
            
        }
    } else {
        banner_mute.onclick = () => {
           
            if (sound_bool = !sound_bool) {
                banner_mute_img.setAttribute('src', sound_on)
            } else {
                banner_mute_img.setAttribute('src', sound_off)
            }

            reel_video.muted = !reel_video.muted
        }
    }

}





module.exports ={
    arrow_up,
    arrow_down,
    sound_on,
    sound_off,
    reelUp,
    reelDown,
}















